// Header animations
const Header = document.querySelector('.main-header');
window.addEventListener('scroll', () => {
  const top =
    (window.pageYOffset || document.documentElement.scrollTop) -
    (document.documentElement.clientTop || 0);

  if (top >= 60) {
    Header.classList.add('fixed');
  } else {
    Header.classList.remove('fixed');
  }
});
