// Toggle block
const ToggleBlock = (el, container, classes, options = { overlay: false }) => {
  const overlay = document.querySelector('.Overlay');

  if (!el) return;

  el.addEventListener('click', () => {
    if (container.classList.contains(classes[0])) {
      el.classList.remove(classes[1]);
      container.classList.remove(classes[0]);
      if (options.overlay) overlay.classList.remove('show');
      if (options.scrollBlock) {
        document.getElementsByTagName('body')[0].classList.remove('no-scroll');
        document.getElementsByTagName('html')[0].classList.remove('no-scroll');
      }
    } else {
      el.classList.add(classes[1]);
      container.classList.add(classes[0]);
      if (options.overlay) overlay.classList.add('show');
      if (options.scrollBlock) {
        document.getElementsByTagName('body')[0].classList.add('no-scroll');
        document.getElementsByTagName('html')[0].classList.add('no-scroll');
      }
    }
  });
};

// Toggle menu
const hamburger = document.querySelector('.ToggleMenu');
const menu = document.querySelector('.Header__menu');
ToggleBlock(hamburger, menu, ['visible', 'is-active'], {
  overlay: true,
  scrollBlock: true,
});
