import 'whatwg-fetch';
import '../../components/header/js/toggleMenu';
import '../../components/header/js/headerScroll';
import Accodion from '../../components/Accordion/Accordion';
import ScrollTo from '../../components/ScrollTo/ScrollTo';

document.addEventListener('DOMContentLoaded', () => {
  const pricesBtn = document.querySelector('.get-block-item .Link');

  Accodion();

  ScrollTo(pricesBtn, document.querySelector('[data-calc-mini]').getBoundingClientRect().top);
});
