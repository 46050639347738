/**
 * FAQ component
 */

const Accordion = (
  options = {
    faqContainer: document.querySelector('.Accordion'),
    headingSelector: '.Accordion__heading',
    contentSelector: '.Accordion__content',
    faqItemsSelector: '.Accordion',
    contentWrapperSelector: '.Accordion__body',
  },
) => {
  const openFaqContent = event => {
    const parent = event.target.parentNode;
    const heightContent = parent.querySelector(options.contentSelector).offsetHeight;

    if (parent.classList.contains('open')) {
      parent.classList.remove('open');
      parent.querySelector(options.contentWrapperSelector).style.height = '0px';
    } else {
      parent.classList.add('open');
      parent.querySelector(options.contentWrapperSelector).style.height = `${heightContent}px`;
    }
  };

  if (options.faqContainer) {
    const faqItems = document.querySelectorAll(options.faqItemsSelector);
    [...faqItems].forEach(i => {
      const heading = i.querySelector(options.headingSelector);
      heading.addEventListener('click', openFaqContent, false);
    });
  }
};

export default Accordion;
