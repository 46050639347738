const ScrollTo = el => {
  function currentYPosition() {
    // Firefox, Chrome, Opera, Safari
    if (self.pageYOffset) return self.pageYOffset; /* eslint-disable-line */

    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop) {
      return document.documentElement.scrollTop;
    } /* eslint-disable-line */
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) return document.body.scrollTop;
    return 0;
  }

  function elmYPosition(eID) {
    const elm = document.getElementById(eID);
    let y = elm.offsetTop;
    let node = elm;
    while (node.offsetParent && node.offsetParent !== document.body) {
      node = node.offsetParent;
      y += node.offsetTop;
    }
    return y;
  }

  function smoothScroll(eID) {
    const startY = currentYPosition();
    const stopY = elmYPosition(eID) - 80;
    const distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, stopY);
      return; /* eslint-disable-line */
    }
    let speed = Math.round(distance / 100);
    if (speed >= 20) speed = 20;
    const step = Math.round(distance / 25);
    let leapY = stopY > startY ? startY + step : startY - step;
    let timer = 0;
    if (stopY > startY) {
      for (let i = startY; i < stopY; i += step) {
        setTimeout(`window.scrollTo(0, ${leapY})`, timer * speed); /* eslint-disable-line */
        leapY += step;
        if (leapY > stopY) leapY = stopY;
        timer++; /* eslint-disable-line */
      }
      return;
    }
    for (let i = startY; i > stopY; i -= step) {
      setTimeout(`window.scrollTo(0, ${leapY})`, timer * speed); /* eslint-disable-line */
      leapY -= step;
      if (leapY < stopY) leapY = stopY;
      timer++; /* eslint-disable-line */
    }
  }

  el.addEventListener('click', e => {
    smoothScroll(e.target.dataset.scrollTo);
  });
};

export default ScrollTo;
